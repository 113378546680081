<template>
    <modal
        :name="modalName"
    >
        <template v-slot:title>
            Upload CVS file
        </template>

        <template v-slot:default="data">
            <div class="py-8">
                <input
                    type="file"
                    @change="handleFileChange"
                >
                <p class="text-sm text-red-600 pl-2" v-if="errors && errors.csv">
                    {{ errors.csv[0] }}
                </p>
            </div>
            <div class="flex">
                <v-button
                    class="bg-gray-300 p-3 w-44 rounded-lg mr-4"
                    @click="$modal.close(this.modalName)"
                >
                    Cancel
                </v-button>

                <v-button
                    class="bg-green-300 p-3 w-44 rounded-lg"
                    :disabled="loading"
                    @click="upload"
                >
                    Upload
                </v-button>
            </div>
        </template>
    </modal>
</template>

<script>
import Input from "@/components/Input";
export default {
    name: "UploadFileModal",

    components: {Input},

    data() {
        return {
            modalName: 'sim-cards-upload-file-modal',
            model: 'sim-cards',
            loading: false,
            csv: null,
            errors: null,
        }
    },

    methods: {
        handleFileChange(e) {
            this.csv = e.target.files[0];
        },

        upload() {
            this.loading = true;
            let formData = new FormData;

            formData.set('csv', this.csv);

            this.$axios.post(`/${this.model}/create-from-csv`, formData)
                .then(res => {
                    if (res) {
                        this.$store.dispatch('unshiftSimCards', res.data);
                    }

                    this.$modal.close(this.modalName);
                    this.loading = false;
                })
                .catch(e => {
                    if (e.status === 422) {
                        this.errors = e.data.errors;
                    }

                    this.loading = false;
                });
        }
    }
}
</script>

<style scoped>

</style>