export default {
    state: () => ({
        simCards: [],
    }),
    mutations: {
        setSimCards(state, payload) {
            state.simCards = payload;
        }
    },
    actions: {
        setSimCards({state, commit}, payload) {
            commit('setSimCards', payload);
        },
        unshiftSimCards({state, commit}, payload) {
            let simCards = state.simCards;

            payload.forEach(item => {
                simCards.unshift(item);
            });

            commit('setSimCards', simCards);
        },
        spliceSimCardsById({state, commit}, id) {
            let simCards = state.simCards;

            simCards = simCards.filter(simCard => simCard.id !== id);

            commit('setSimCards', simCards);
        },
    },
    getters: {
        getSimCards(state) {
            return state.simCards;
        }
    }
}