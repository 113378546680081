<template>
    <layout>
        <v-details
            :model="model"
            :title="`Sim card details`"
            :fields="fields"
            :data="data"
        />
    </layout>
</template>

<script>
import {USER_ROLES} from "@/views/users/constants";

export default {
    name: "SimCardsDetail",

    data() {
        return {
            model: 'sim-cards',
            data: {},
            fields: [
                {text: 'ICCID', key: 'iccid'},
                {text: 'Valid days', key: 'valid_days'},
                {text: 'Expiration days', key: 'expiration_days'},
                {text: 'Creator', key: 'creator_name'},
                {text: 'Created at', key: 'created_at'},
            ],
            userRoles: USER_ROLES,
        }
    },
    created() {
        this.loadData();
    },
    methods: {
        loadData() {
            this.$axios
                .get(`/${this.model}/${this.$route.params.id}/show`)
                .then((response) => {
                    this.data = response.data;
                    this.parseData(response.data);
                })
        },

        parseData(data) {
            this.data['creator_name'] = `${data.creator.first_name} ${data.creator.last_name}`;
            this.data['created_at'] = this.moment(this.data['created_at']).format('LLL');
        }
    },
};
</script>
