<template>
    <modal
        :name="modalName"
    >
        <template v-slot:title>
            Confirm activation
        </template>

        <template v-slot:default="data">
            <div class="pt-8 flex">
                <v-button
                    class="bg-gray-300 p-3 w-44 rounded-lg mr-4"
                    @click="$modal.close(this.modalName)"
                >
                    Cancel
                </v-button>

                <v-button
                    class="bg-green-300 p-3 w-44 rounded-lg"
                    :disabled="loading"
                    @click="submit(data.data)"
                >
                    Activate
                </v-button>
            </div>
        </template>
    </modal>
</template>

<script>
export default {
    name: "ActivationConfirmModal",

    data() {
        return {
            modalName: 'sim-card-activations-activate-confirm-modal',
            model: 'sim-card-activations',
            loading: false,
        }
    },

    methods: {
        submit(id) {
            this.loading = true;

            this.$axios.post(`/${this.model}/${id}/activate`)
                .then(res => {
                    if (res) {
                        this.$store.dispatch('updateSimCardActivationById', res.data);
                        this.$store.dispatch('setSelectedSimCardActivation', res.data);
                    }

                    this.$modal.close(this.modalName);
                    this.loading = false;
                });
        }
    }
}
</script>

<style scoped>

</style>