<template>
    <layout>
        <v-details
            :model="model"
            :title="`${data.first_name} ${data.last_name}`"
            :fields="fields"
            :data="data"
        />
    </layout>
</template>

<script>
import {USER_ROLES} from "@/views/users/constants";

export default {
    name: "UsersDetail",

    data() {
        return {
            model: 'users',
            data: {},
            fields: [
                {text: 'First name', key: 'first_name'},
                {text: 'Last name', key: 'last_name'},
                {text: 'Email', key: 'email'},
                {text: 'Role', key: 'role'},
                {text: 'Created at', key: 'created_at'},
            ],
            userRoles: USER_ROLES,
        }
    },
    created() {
        this.loadData();
    },
    methods: {
        loadData() {
            this.$axios
                .get(`/${this.model}/${this.$route.params.id}/show`)
                .then((response) => {
                    this.data = response.data;
                    this.parseData(response.data);
                })
        },

        parseData(data) {
            let role = this.userRoles.find(userRole => userRole.id === data.role);

            this.data['role'] = !!role ? role.name : 'Not selected';
            this.data['created_at'] = this.moment(this.data['created_at']).format('LLL');
        }
    },
};
</script>
