<template>
    <div>
        <label
            class="mb-1 ml-2 text-sm text-gray-800 font-medium cursor-pointer"
        >Статус</label
        >
        <multiselect
            v-model="selected"
            class="app-multiselect w-full max-w-[600px]"
            :showLabels="false"
            :maxHeight="400"
            :label="label"
            :searchable="searchable"
            :options="options"
            @close="$emit('update:modelValue', this.selected)"
        />
        <p class="text-sm text-red-600 pl-2" v-if="errorMessage">
            {{ errorMessage }}
        </p>
    </div>
</template>

<script>
export default {
    props: {
        options: {
            type: Object,
        },
        label: {
            type: String,
        },
        value: {
            default() {
                return null;
            }
        },
        searchable: {
            type: Boolean,
            default() {
                return false;
            }
        },
        errorMessage: String,
    },

    watch: {
        value(value) {
            if (value) this.selected = value;
        }
    },

    data() {
        return {
            selected: null,
        };
    },
};
</script>

<style scoped lang="scss"></style>
