<template>
    <layout>
        <delete-confirm-modal />

        <div class="py-8 px-8">
            <v-table-actions
                :model="model"
                :title="tableActions.title"
                :create="tableActions.create"
                :search="tableActions.search"
                :filters="tableActions.filters"
                :filters-data="filtersData"
                :upload="tableActions.upload"
                :upload-file-modal-name="modals.uploadFileModalName"
                @inputSearch="inputSearch"
                @handleFilters="handleFilters"
            />

            <div class="py-6">
                <template v-if="!simCardActivations.length">
                    No items...
                </template>

                <template v-if="simCardActivations.length">
                    <v-table
                        :model="model"
                        :edit="tableOptions.edit"
                        :table-heads="tableHeads"
                        :table-data="simCardActivations"
                        :delete-confirm-modal-name="modals.deleteConfirmModalName"
                    />
                </template>
            </div>
        </div>
    </layout>
</template>

<script>
import DeleteConfirmModal from "@/views/sim_activations/modals/DeleteConfirmModal";
import {STATUSES} from "@/views/sim_activations/constants";
import moment from "moment";
import {mapGetters} from "vuex";
import _ from "lodash";

export default {
    name: "SimCardActivationsIndex",

    components: {DeleteConfirmModal},

    data() {
        return {
            model: 'sim-card-activations',
            loading: false,
            statuses: STATUSES,

            modals: {
                uploadFileModalName: '',
                deleteConfirmModalName: 'sim-card-activations-delete-confirm-modal',
            },

            tableActions: {
                title: 'Sim card activations',
                create: false,
                search: true,
                filters: true,
                upload: false,
            },

            tableHeads: [
                {text: 'ICCID', key: 'iccid'},
                {text: 'Start date', key: 'start_date'},
                {text: 'End date', key: 'end_date'},
                {text: 'Status', key: 'status'},
                {text: 'Email', key: 'email'},
            ],

            tableOptions: {
                edit: false,
            },

            searchKeywords: null,

            filters: null,
            filtersData: [
                {text: 'Start date', key: 'start_date', type: 'date'},
                {text: 'End date', key: 'end_date', type: 'date'},
                {text: 'Start created date', key: 'start_created_at', type: 'date'},
                {text: 'End created date', key: 'end_created_at', type: 'date'},
            ],
        };
    },

    created() {
        this.initData();
    },

    computed: {
        ...mapGetters({
            simCardActivations: 'getSimCardActivations',
        })
    },

    methods: {
        initData() {
            this.loading = true;

            let params = {};

            if (this.searchKeywords) {
                params.keywords = this.searchKeywords;
            }

            if (this.filters) {
                params.filters = this.filters;
            }

            this.$axios.get(`/${this.model}`, {
                params
            })
                .then(({data}) => {
                    if (data) {
                        this.$store.dispatch('setSimCardActivations', this.parseData(data.data));
                    }

                    this.loading = false;
                });
        },

        inputSearch: _.debounce(function (e) {
            this.searchKeywords = e;
            this.initData();
        }, 300),

        handleFilters(e) {
            this.filters = e;
            this.initData();
        },

        parseData(data) {
            data.forEach(item => {
                let status = this.statuses.find(status => status.id === item.status);

                item['status'] = !!status ? status.name : 'Not selected';
                item['start_date'] = moment(item['start_date']).format('MM/DD/YYYY');
                item['end_date'] = moment(item['end_date']).format('MM/DD/YYYY');
            });

            return data;
        }
    },
};
</script>
