export default {
    state: () => ({
        users: [],
    }),
    mutations: {
        setUsers(state, payload) {
            state.users = payload;
        }
    },
    actions: {
        setUsers({state, commit}, payload) {
            commit('setUsers', payload);
        }
    },
    getters: {
        getUsers(state) {
            return state.users;
        }
    }
}