<template>
    <div class="py-8 px-8">
        <h1 class="font-semibold text-lg mb-2">
            {{ title }}
        </h1>

        <div class="py-8">
            <div v-for="(field, key) in fields" :key="field + key">
                <div class="flex items-center mb-4 pb-2">
                    <div class="w-52">
                        <span class="text-gray-500 font-semibold mr-10">{{ field.text }}:</span>
                    </div>

                    <div>
                        <span class="text-black">{{ data[field.key] }}</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "Details",
    props: {
        model: {
            type: String,
            default() {
                return null;
            }
        },
        title: {
            type: String,
            default() {
                return null;
            }
        },
        fields: {
            type: Array,
            default() {
                return []
            }
        },
        data: {
            type: Object,
            default() {
                return {}
            }
        }
    }
}
</script>

<style scoped>

</style>