<template>
    <layout>
        <activation-confirm-modal />

        <v-details
            :model="model"
            :title="`Sim card activation details`"
            :fields="fields"
            :data="data"
        />
    </layout>
</template>

<script>
import {mapGetters} from "vuex";

export default {
    name: "SimCardRechargesDetail",

    data() {
        return {
            model: 'sim-card-recharges',
            fields: [
                {text: 'ICCID', key: 'iccid'},
                {text: 'Start date', key: 'start_date'},
                {text: 'End date', key: 'end_date'},
                {text: 'Status', key: 'status'},
                {text: 'Email', key: 'email'},
                {text: 'Price', key: 'price'},
                {text: 'Created at', key: 'created_at'},
            ],
        }
    },

    created() {
        this.loadData();
    },

    computed: {
        ...mapGetters({
            data: 'getSelectedSimCardRecharge',
            statuses: 'getStatuses',
        }),
    },

    methods: {
        loadData() {
            this.$axios.get(`/${this.model}/${this.$route.params.id}/show`)
                .then((response) => {
                    this.$store.dispatch('setSelectedSimCardRecharge', response.data);
                })
        },
    },
};
</script>
