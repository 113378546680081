<template>
    <modal name="filter" class="justify-end" type-menu>
        <template v-slot:menu>
            <div class="h-screen bg-white px-4 py-4 flex flex-col justify-between">
                <div class="filter-content">
                    <div class="flex justify-between mb-8 items-center">
                        <h1 class="mr-8 font-bold text-lg text-gray-800">
                            Filters
                        </h1>
                        <button @click="$modal.close('filter')"
                                class="button-close close flex items-center">
                            <icon name="close"/>
                        </button>
                    </div>
                    <div>
                        <template v-for="item in data">
                            <div class="mb-4">
                                <component
                                    :type="item.type"
                                    :labelText="item.text"
                                    :is="`v-input`"
                                    v-model="filtersData[item.key]"
                                />
                            </div>
                        </template>
                    </div>
                </div>
                <div class="filter__buttons flex justify-center">
                    <v-button class="bg-blue-300 py-3 px-8 rounded-lg" @click="submit">
                        Accept
                    </v-button>

                    <button class="border-gray-800 border text-gray-800 py-3 px-8 rounded-lg text-gray-800 text-sm" @click="clear">
                        Clear
                    </button>
                </div>
            </div>
        </template>
    </modal>
</template>

<script>
export default {
    name: "Filters",

    props: {
        data: {
            type: Array,
            default() {
                return [];
            }
        }
    },

    data() {
        return {
            filtersData: {}
        }
    },

    methods: {
        submit() {
            this.$emit('submited', this.filtersData);
            this.$modal.close('filter');
        },

        clear() {
            this.filtersData = {};
            this.$emit('submited', this.filtersData);
            this.$modal.close('filter');
        }
    },
}
</script>

<style lang="scss">
.filter {
    &-content {
        width: 24rem;
    }

    &__buttons {
        button {
            margin: 0 1rem 0 0;
        }
    }
}
</style>