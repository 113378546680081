import {createRouter, createWebHashHistory, createWebHistory} from "vue-router";
import Index from "../views/Index.vue";

// Dashboard
import DashboardIndex from "../views/dashboard/Index";

// Users
import UsersIndex from "../views/users/Index";
import UsersCreate from "../views/users/Create";
import UsersDetail from "../views/users/Detail";
import UsersEdit from "../views/users/Edit";

// Sim cards
import SimCardIndex from "../views/sim_cards/Index";
import SimCardCreate from "../views/sim_cards/Create";
import SimCardDetail from "../views/sim_cards/Detail";

// Sim activations
import SimActivationIndex from "../views/sim_activations/Index";
import SimActivationDetail from "../views/sim_activations/Detail";

// Sim activations
import SimRechargesIndex from "../views/sim_recharges/Index";
import SimRechargesDetail from "../views/sim_recharges/Detail";

const routes = [
    // auth
    {
        path: "/login",
        name: "Login",
        component: () =>
            import(/* webpackChunkName: "login" */ "../views/auth/Login.vue"),
    },

    // Dashboard
    {
        path: "/",
        name: "dashboard-index",
        component: DashboardIndex,
    },

    // users
    {
        path: "/users",
        name: "users",
        component: UsersIndex,
    },

    {
        path: "/users/create",
        name: "users_create",
        component: UsersCreate,
    },

    {
        path: "/users/:id/show",
        name: "users_show",
        component: UsersDetail,
    },

    {
        path: "/users/:id/edit",
        name: "users_edit",
        component: UsersEdit,
    },

    // sim cards
    {
        path: "/sim-cards",
        name: "sim-cards",
        component: SimCardIndex,
    },

    {
        path: "/sim-cards/create",
        name: "sim-cards_create",
        component: SimCardCreate,
    },

    {
        path: "/sim-cards/:id/show",
        name: "sim-cards_show",
        component: SimCardDetail,
    },

    // sim activations
    {
        path: "/sim-card-activations",
        name: "sim-activations",
        component: SimActivationIndex,
    },

    {
        path: "/sim-card-activations/:id/show",
        name: "sim-activations_show",
        component: SimActivationDetail,
    },

    // sim activations
    {
        path: "/sim-card-recharges",
        name: "sim-recharges",
        component: SimRechargesIndex,
    },

    {
        path: "/sim-card-recharges/:id/show",
        name: "sim-recharges_show",
        component: SimRechargesDetail,
    },

    // statistic
    {
        path: "/statistic",
        name: "Statistic",
        component: () =>
            import(/* webpackChunkName: "statistic" */ "../views/Statistic.vue"),
    },
];

const router = createRouter({
    history: createWebHistory(),
    routes,
});

export default router;
