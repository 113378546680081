<template>
    <layout>
        <div class="py-8 px-8">
            <h1 class="font-semibold text-lg">
                {{ title }}
            </h1>

            <form @submit.prevent="submit" class="py-8">
                <div
                    class="mb-8"
                    v-for="field in fields"
                    :key="field.title"
                >
                    <component
                        class="w-1/2"
                        :options="field.options"
                        :label="field.label"
                        :type="field.type"
                        :maska="field.mask"
                        :labelText="field.title"
                        :is="`v-${field.component}`"
                        :error-message="errors && errors[field.key] ? errors[field.key][0] : ''"
                        v-model="field.value"
                    />
                </div>
                <div class="py-8">
                    <v-button
                        class="bg-blue-300 p-3 w-44 rounded-lg"
                        :disabled="loading"
                    >
                        Create
                    </v-button>
                </div>
            </form>
        </div>
    </layout>
</template>

<script>
import {USER_ROLES} from "@/views/users/constants";

export default {
    name: "SimCardsCreate",

    data() {
        return {
            model: 'sim-cards',
            title: 'Create sim card',
            fields: [
                {
                    key: 'iccid',
                    title: "ICCID",
                    component: "input",
                    type: "text",
                    value: '',
                },
                {
                    key: 'valid_days',
                    title: "Valid days",
                    component: "input",
                    type: "number",
                    value: '',
                },
                {
                    key: 'expiration_days',
                    title: "Expiration days",
                    component: "input",
                    type: "number",
                    value: '',
                },
            ],
            errors: null,
            loading: false,
        };
    },

    methods: {
        submit() {
            this.loading = true;
            let formData = new FormData;

            for(let key in this.fields) {
                if (this.fields[key].value) {
                    formData.set(this.fields[key].key, this.fields[key].value);
                }
            }

            this.$axios.post(`/${this.model}/store`, formData)
                .then(res => {
                    this.$router.push({name: this.model});
                    this.loading = false;
                })
                .catch(e => {
                    if (e.status === 422) {
                        this.errors = e.data.errors;
                    }

                    this.loading = false;
                });
        }
    }
}
</script>

<style lang="sass">

</style>