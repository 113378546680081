<template>
    <layout>
        <div class="py-8 px-8">
            <template v-if="customersData">
                <div class="">
                    <vue3-chart-js type="line" :data="customersData"/>
                </div>
            </template>
        </div>
    </layout>
</template>

<script>
import Vue3ChartJs from "@j-t-mcc/vue3-chartjs";

export default {
    name: "DashboardIndex",

    components: {
        Vue3ChartJs,
    },

    data() {
        return {
            customersData: null,
        }
    },

    created() {
        this.loadData();
    },

    methods: {
        loadData() {
            this.$axios.get('/users/charts/line/customers-data')
                .then(res => {
                    this.customersData = res.data;
                });
        }
    }
}
</script>

<style scoped>

</style>