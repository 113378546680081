<template>
    <layout>
        <div class="py-8 px-8">
            <h1 class="font-semibold text-lg">
                Edit User
            </h1>

            <form @submit.prevent="submit" class="py-8">
                <div
                    class="mb-8"
                    v-for="field in fields"
                    :key="field.title"
                >
                    <component
                        class="w-1/2"
                        :options="field.options"
                        :label="field.label"
                        :type="field.type"
                        :maska="field.mask"
                        :labelText="field.title"
                        :is="`v-${field.component}`"
                        :error-message="errors && errors[field.key] ? errors[field.key][0] : ''"
                        :value="field.value"
                        v-model="field.value"
                    />
                </div>
                <div class="py-8">
                    <v-button
                        class="bg-blue-300 p-3 w-44 rounded-lg"
                        :disabled="loading"
                    >
                        Update
                    </v-button>
                </div>
            </form>
        </div>
    </layout>
</template>

<script>
import {USER_ROLES} from "@/views/users/constants";

export default {
    name: "",

    data() {
        return {
            model: 'users',
            fields: [
                {
                    key: 'first_name',
                    title: "First name",
                    component: "input",
                    type: "text",
                    value: '',
                },
                {
                    key: 'last_name',
                    title: "Last name",
                    component: "input",
                    type: "text",
                    value: '',
                },
                {
                    key: 'email',
                    title: "Email",
                    component: "input",
                    type: "email",
                    value: '',
                },
                {
                    key: 'role',
                    title: "Role",
                    component: "select",
                    label: 'name',
                    options: USER_ROLES,
                    value: '',
                },
                {
                    key: 'password',
                    title: "Password",
                    component: "input",
                    type: "password",
                    value: '',
                },
            ],
            userRoles: USER_ROLES,
            errors: null,
            loading: false,
        };
    },

    created() {
        this.loadData();
    },

    methods: {
        loadData() {
            this.$axios
                .get(`/${this.model}/${this.$route.params.id}/edit`)
                .then((response) => {
                    this.parseData(response.data);
                })
        },

        parseData(data) {
            this.fields.forEach(field => {
                if (field.key === 'role') {
                    let role = this.userRoles.find(userRole => userRole.id === data.role);
                    field.value = role ? role : null;
                } else {
                    field.value = data[field.key];
                }
            });
        },

        submit() {
            this.loading = true;
            let formData = new FormData;

            for(let key in this.fields) {
                if (this.fields[key].value) {
                    switch (this.fields[key].key) {
                        case 'role':
                            if (this.fields[key].value) {
                                formData.set(this.fields[key].key, this.fields[key].value.id);
                            }
                            break;
                        default:
                            formData.set(this.fields[key].key, this.fields[key].value);
                    }
                }
            }

            this.$axios.post(`/${this.model}/${this.$route.params.id}/update`, formData)
                .then(res => {
                    this.$router.push({name: this.model});
                    this.loading = false;
                })
                .catch(e => {
                    if (e.status === 422) {
                        this.errors = e.data.errors;
                    }

                    this.loading = false;
                });
        }
    }
};
</script>
