<template>
    <layout>
        <div class="py-8 px-8">
            <v-table-actions
                :model="model"
                :title="tableActions.title"
                :create="tableActions.create"
                :search="tableActions.search"
                :filters="tableActions.filters"
                :filters-data="filtersData"
                @inputSearch="inputSearch"
                @handleFilters="handleFilters"
            />

            <div class="py-6">
                <template v-if="!users.length">
                    No items...
                </template>

                <template v-if="users.length">
                    <v-table
                        :model="model"
                        :table-heads="tableHeads"
                        :table-data="users"
                        :delete-confirm-modal-name="tableOptions.deleteConfirmModalName"
                    />
                </template>
            </div>
        </div>

        <delete-confirm-modal
            @deleted="initData"
        />
    </layout>
</template>

<script>
import DeleteConfirmModal from "@/views/users/modals/DeleteConfirmModal";
import _ from 'lodash';
import {mapGetters} from "vuex";

export default {
    name: "UsersIndex",

    components: {DeleteConfirmModal},

    data() {
        return {
            model: 'users',
            loading: false,

            tableActions: {
                title: 'Users',
                create: true,
                search: true,
                filters: true,
            },

            tableHeads: [
                {text: 'First name', key: 'first_name'},
                {text: 'Last name', key: 'last_name'},
                {text: 'Email', key: 'email'},
            ],

            tableOptions: {
                deleteConfirmModalName: 'users-delete-confirm-modal',
            },

            searchKeywords: null,

            filters: null,
            filtersData: [
                {text: 'Start created date', key: 'start_created_at', type: 'date'},
                {text: 'End created date', key: 'end_created_at', type: 'date'},
            ],
        };
    },

    created() {
        this.initData();
    },

    computed: {
        ...mapGetters({
            users: 'getUsers',
        })
    },

    methods: {
        initData() {
            this.loading = true;

            let params = {};

            if (this.searchKeywords) {
                params.keywords = this.searchKeywords;
            }

            if (this.filters) {
                params.filters = this.filters;
            }

            this.$axios.get(`/${this.model}`, {
                params
            })
                .then(({data}) => {
                    if (data) {
                        this.$store.dispatch('setUsers', data.data);
                    }

                    this.loading = false;
                });
        },

        inputSearch: _.debounce(function (e) {
            this.searchKeywords = e;
            this.initData();
        }, 300),

        handleFilters(e) {
            this.filters = e;
            this.initData();
        }
    },
};
</script>
