<template>
    <table class="min-w-full leading-normal">
        <thead>
        <tr>
            <th
                class="px-4 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider"
            >
                #
            </th>
            <template v-for="head in tableHeads">
                <th
                    class="px-4 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider"
                >
                    {{ head.text }}
                </th>
            </template>
            <th
                class="px-4 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider"
            ></th>
        </tr>
        </thead>

        <tbody>
            <template v-for="(data, i) in tableData">
                <tr>
                    <td
                        class="px-4 py-4 border-b border-gray-200 bg-white text-sm"
                    >{{ i + 1 }}
                    </td>

                    <template v-for="head in tableHeads">
                        <td
                            class="px-4 py-4 border-b border-gray-200 bg-white text-sm"
                        >
                            {{ data[head.key] }}
                        </td>
                    </template>

                    <td class="px-4 py-4 border-b border-gray-200 bg-white text-sm">
                        <div class="flex item-center justify-center">
                            <template v-if="show">
                                <router-link
                                    :to="`/${model}/${data.id}/show`"
                                    class="w-5 mr-3 transition duration-200 hover:text-blue-300"
                                >
                                    <icon name="eye"/>
                                </router-link>
                            </template>

                            <template v-if="edit">
                                <router-link
                                    :to="`/${model}/${data.id}/edit`"
                                    class="w-5 mr-3 transition duration-200 hover:text-blue-300"
                                >
                                    <icon name="edit"/>
                                </router-link>
                            </template>

                            <template v-if="destroy">
                                <button
                                    class="w-5 transition duration-200 hover:text-blue-300"
                                    @click.prevent="$modal.open(deleteConfirmModalName, data.id)"
                                >
                                    <icon name="basket"/>
                                </button>
                            </template>
                        </div>
                    </td>
                </tr>
            </template>
        </tbody>
    </table>
</template>

<script>
export default {
    name: "Table",
    props: {
        model: {
            type: String,
            default() {
                return null;
            }
        },
        show: {
            type: Boolean,
            default() {
                return true;
            }
        },
        edit: {
            type: Boolean,
            default() {
                return true;
            }
        },
        destroy: {
            type: Boolean,
            default() {
                return true;
            }
        },
        tableHeads: {
            type: Array,
            default() {
                return {}
            }
        },
        tableData: {
            type: Object,
            default() {
                return {}
            }
        },
        deleteConfirmModalName: {
            type: String,
            default() {
                return null;
            }
        },
    },

    data() {
        return {};
    },
}
</script>

<style scoped>

</style>