import moment from "moment";

export default {
    state: () => ({
        simCardActivations: [],
        selectedSimCardActivation: {},
        STATUSES: [
            {
                id: 1,
                name: 'New'
            },
            {
                id: 2,
                name: 'Awaiting'
            },
            {
                id: 3,
                name: 'Activated'
            },
        ],
    }),
    mutations: {
        setSimCardActivations(state, payload) {
            state.simCardActivations = payload;
        },

        setSelectedSimCardActivation(state, payload) {
            state.selectedSimCardActivation = payload;
        }
    },
    actions: {
        setSimCardActivations({state, commit}, payload) {
            commit('setSimCardActivations', payload);
        },

        unshiftSimCardActivations({state, commit}, payload) {
            let simCardActivations = state.simCardActivations;

            simCardActivations.unshift(...payload);

            commit('setSimCardActivations', simCardActivations);
        },

        spliceSimCardActivationsById({state, commit}, id) {
            let simCardActivations = state.simCardActivations;

            simCardActivations = simCardActivations.filter(activation => activation.id !== id);

            commit('setSimCardActivations', simCardActivations);
        },

        updateSelectedSimCardActivation({state, commit}, payload) {
            let simCardActivations = state.simCardActivations;

            simCardActivations = simCardActivations.map(activation => {
                if (activation.id === payload.id) {
                    return payload;
                }

                return activation;
            });

            commit('setSimCardActivations', simCardActivations);
        },

        setSelectedSimCardActivation({state, commit}, payload) {
            commit('setSelectedSimCardActivation', payload);
        }
    },
    getters: {
        getSimCardActivations(state) {
            return state.simCardActivations;
        },

        getSelectedSimCardActivation(state) {
            let simActivation = state.selectedSimCardActivation;
            let status = state.STATUSES.find(status => status.id === simActivation.status);

            simActivation.status = !!status ? status.name : 'Not selected';
            simActivation.start_date = moment(simActivation.start_date).format('LLL');
            simActivation.end_date = moment(simActivation.end_date).format('LLL');
            simActivation.created_at = moment(simActivation.created_at).format('LLL');

            return simActivation;
        },

        getStatuses(state) {
            return state.STATUSES;
        }
    }
}