<template>
    <layout>
        <delete-confirm-modal />
        <upload-file-modal />

        <div class="py-8 px-8">
            <v-table-actions
                :model="model"
                :title="tableActions.title"
                :create="tableActions.create"
                :search="tableActions.search"
                :filters="tableActions.filters"
                :filters-data="filtersData"
                :upload="tableActions.upload"
                :upload-file-modal-name="modals.uploadFileModalName"
                @inputSearch="inputSearch"
                @handleFilters="handleFilters"
            />

            <div class="py-6">
                <template v-if="!simCards.length">
                    No items...
                </template>

                <template v-if="simCards.length">
                    <v-table
                        :model="model"
                        :edit="tableOptions.edit"
                        :table-heads="tableHeads"
                        :table-data="simCards"
                        :delete-confirm-modal-name="modals.deleteConfirmModalName"
                    />
                </template>
            </div>
        </div>
    </layout>
</template>

<script>
import DeleteConfirmModal from "@/views/sim_cards/modals/DeleteConfirmModal";
import _ from 'lodash';
import {mapGetters} from "vuex";
import UploadFileModal from "@/views/sim_cards/modals/UploadFileModal";

export default {
    name: "SimCardsIndex",

    components: {UploadFileModal, DeleteConfirmModal},

    data() {
        return {
            model: 'sim-cards',
            loading: false,

            modals: {
                uploadFileModalName: 'sim-cards-upload-file-modal',
                deleteConfirmModalName: 'sim-card-delete-confirm-modal',
            },

            tableActions: {
                title: 'Sim cards',
                create: true,
                search: true,
                filters: true,
                upload: true,
            },

            tableHeads: [
                {text: 'ICCID', key: 'iccid'},
                {text: 'Valid days', key: 'valid_days'},
            ],

            tableOptions: {
                edit: false,
            },

            searchKeywords: null,

            filters: null,
            filtersData: [
                {text: 'Start created date', key: 'start_created_at', type: 'date'},
                {text: 'End created date', key: 'end_created_at', type: 'date'},
            ],
        };
    },

    created() {
        this.initData();
    },

    computed: {
        ...mapGetters({
            simCards: 'getSimCards',
        })
    },

    methods: {
        initData() {
            this.loading = true;

            let params = {};

            if (this.searchKeywords) {
                params.keywords = this.searchKeywords;
            }

            if (this.filters) {
                params.filters = this.filters;
            }

            this.$axios.get(`/${this.model}`, {
                params
            })
                .then(({data}) => {
                    if (data) {
                        this.$store.dispatch('setSimCards', data.data);
                    }

                    this.loading = false;
                });
        },

        inputSearch: _.debounce(function (e) {
            this.searchKeywords = e;
            this.initData();
        }, 300),

        handleFilters(e) {
            this.filters = e;
            this.initData();
        }
    },
};
</script>
