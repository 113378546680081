import moment from "moment";

export default {
    state: () => ({
        simCardRecharges: [],
        selectedSimCardRecharge: {},
        STATUSES: [
            {
                id: 1,
                name: 'New'
            },
            {
                id: 2,
                name: 'Payed'
            },
            {
                id: 3,
                name: 'Activated'
            },
        ],
    }),
    mutations: {
        setSimCardRecharges(state, payload) {
            state.simCardRecharges = payload;
        },

        setSelectedSimCardRecharge(state, payload) {
            state.selectedSimCardRecharge = payload;
        }
    },
    actions: {
        setSimCardRecharges({state, commit}, payload) {
            commit('setSimCardRecharges', payload);
        },

        unshiftSimCardRecharges({state, commit}, payload) {
            let simCardRecharges = state.simCardRecharges;

            simCardRecharges.unshift(...payload);

            commit('setSimCardRecharges', simCardRecharges);
        },

        spliceSimCardRechargesById({state, commit}, id) {
            let simCardRecharges = state.simCardRecharges;

            simCardRecharges = simCardRecharges.filter(recharge => recharge.id !== id);

            commit('setSimCardRecharges', simCardRecharges);
        },

        updateSelectedSimCardRecharge({state, commit}, payload) {
            let simCardRecharges = state.simCardRecharges;

            simCardRecharges = simCardRecharges.map(recharge => {
                if (recharge.id === payload.id) {
                    return payload;
                }

                return recharge;
            });

            commit('setSimCardRecharges', simCardRecharges);
        },

        setSelectedSimCardRecharge({state, commit}, payload) {
            commit('setSelectedSimCardRecharge', payload);
        }
    },
    getters: {
        getSimCardRecharges(state) {
            return state.simCardRecharges;
        },

        getSelectedSimCardRecharge(state) {
            let simRecharge = state.selectedSimCardRecharge;
            let status = state.STATUSES.find(status => status.id === simRecharge.status);

            simRecharge.status = !!status ? status.name : 'Not selected';
            simRecharge.start_date = moment(simRecharge.start_date).format('LLL');
            simRecharge.end_date = moment(simRecharge.end_date).format('LLL');
            simRecharge.created_at = moment(simRecharge.created_at).format('LLL');

            return simRecharge;
        },

        getSimCardRechargesStatuses(state) {
            return state.STATUSES;
        }
    }
}