export const USER_ROLES = [
    {
        id: 1,
        name: 'Admin'
    },
    {
        id: 2,
        name: 'Manager'
    },
    {
        id: 3,
        name: 'Customer'
    },
];