<template>
    <modal
        :name="modalName"
    >
        <template v-slot:title>
            Confirm delete

            <p class="italic text-sm">
                Sim activations and sim recharges depends this sim card also will deleted!
            </p>
        </template>

        <template v-slot:default="data">
            <div class="pt-8 flex justify-end w-full">
                <v-button
                    class="bg-gray-300 p-3 w-44 rounded-lg mr-4"
                    @click="$modal.close(this.modalName)"
                >
                    Cancel
                </v-button>

                <v-button
                    class="bg-red-300 p-3 w-44 rounded-lg"
                    :disabled="loading"
                    @click="destroy(data.data)"
                >
                    Delete
                </v-button>
            </div>
        </template>
    </modal>
</template>

<script>
export default {
    name: "DeleteConfirmModal",

    data() {
        return {
            modalName: 'sim-card-delete-confirm-modal',
            model: 'sim-cards',
            loading: false,
        }
    },

    methods: {
        destroy(id) {
            this.loading = true;

            this.$axios.delete(`/${this.model}/${id}/delete`)
                .then(res => {
                    if (res) {
                        this.$store.dispatch('spliceSimCardsById', res.data.id);
                    }

                    this.$modal.close(this.modalName);
                    this.loading = false;
                });
        }
    }
}
</script>

<style scoped>

</style>