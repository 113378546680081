<template>
    <layout>
        <activation-confirm-modal />

        <v-details
            :model="model"
            :title="`Sim card activation details`"
            :fields="fields"
            :data="data"
        />

        <template v-if="data && data.status !== statuses[2].name">
            <div class="pb-8 px-8">
                <h1 class="font-semibold text-lg mb-2">
                    Activate this sim card?
                </h1>

                <v-button
                    class="bg-green-300 p-3 w-44 rounded-lg"
                    @click="$modal.open('sim-card-activations-activate-confirm-modal', data.id)"
                >
                    Activate
                </v-button>
            </div>
        </template>
    </layout>
</template>

<script>
import ActivationConfirmModal from "@/views/sim_activations/modals/ActivationConfirmModal";
import {mapGetters} from "vuex";

export default {
    name: "SimCardActivationsDetail",
    components: {ActivationConfirmModal},
    data() {
        return {
            model: 'sim-card-activations',
            fields: [
                {text: 'ICCID', key: 'iccid'},
                {text: 'Start date', key: 'start_date'},
                {text: 'End date', key: 'end_date'},
                {text: 'Status', key: 'status'},
                {text: 'Email', key: 'email'},
                {text: 'Created at', key: 'created_at'},
            ],
        }
    },

    created() {
        this.loadData();
    },

    computed: {
        ...mapGetters({
            data: 'getSelectedSimCardActivation',
            statuses: 'getStatuses',
        }),
    },

    methods: {
        loadData() {
            this.$axios.get(`/${this.model}/${this.$route.params.id}/show`)
                .then((response) => {
                    this.$store.dispatch('setSelectedSimCardActivation', response.data);
                })
        },
    },
};
</script>
