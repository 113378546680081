<template>
  <modal
      name="delete-confirmation"
  >
    <template v-slot:title>
        Confirm delete
    </template>
  </modal>
</template>

<script>
export default {
  name: "",
  data() {
    return {};
  },
};
</script>
