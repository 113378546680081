export const STATUSES = [
    {
        id: 1,
        name: 'New'
    },
    {
        id: 2,
        name: 'In process'
    },
    {
        id: 3,
        name: 'Activated'
    },
];