<template>
    <div>
        <v-filters
            v-if="filters"
            :data="filtersData"
            @submited="handleFilters"
        />

        <div class="flex items-start justify-between mb-6">
            <h1 class="font-bold text-lg mr-4">
                {{ title }}
            </h1>

            <div class="flex items-center">
                <template v-if="search">
                    <div
                        class="border border-solid border-gray-300 rounded-lg flex items-center overflow-hidden"
                    >
                        <input
                            v-model="searchKeywords"
                            type="text"
                            class="py-2 px-3 text-gray-800 text-sm font-semibold border-0 border-r border-solid border-gray-300 focus:border-gray-300"
                            placeholder="Search"
                            @input="inputSearch"
                        />
                        <v-button
                            class="py-2 px-4 text-sm font-semibold border-0 bg-blue-300 text-white flex"
                        >
                            Search
                        </v-button>
                    </div>
                </template>
            </div>
        </div>

        <div class="flex justify-between items-center">
            <div>
                <template v-if="create">
                    <router-link
                        :to="`/${model}/create`"
                        append
                        class="py-2 px-4 font-semibold border-0 bg-blue-300 text-white flex rounded-lg flex items-center"
                    >
                        <icon name="plus" class="w-3 h-3 fill-current text-white mr-2"/>
                        <span class="text-sm">Add new</span>
                    </router-link>
                </template>
            </div>

            <div class="flex items-center">
                <template v-if="upload">
                    <v-button
                        class="py-2 px-4 bg-blue-300 rounded-lg mr-4"
                        @click.prevent="$modal.open(uploadFileModalName)"
                    >
                        <icon class="w-5 h-5 fill-current text-white" name="cloud-upload"
                        />
                    </v-button>
                </template>
                <template v-if="filters">
                    <v-button
                        class="py-2 px-4 bg-blue-300 rounded-lg"
                        @click.prevent="$modal.open('filter')"
                    >
                        <icon class="w-5 h-5 fill-current text-white" name="filter"
                        />
                    </v-button>
                </template>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "TableActions",

    props: {
        model: {
            type: String,
            default() {
                return null;
            }
        },
        title: {
            type: String,
            default() {
                return null;
            }
        },
        create: {
            type: Boolean,
            default() {
                return true;
            }
        },
        search: {
            type: Boolean,
            default() {
                return false;
            }
        },
        filters: {
            type: Boolean,
            default() {
                return false;
            }
        },
        filtersData: {
            type: Array,
            default() {
                return [];
            }
        },
        upload: {
            type: Boolean,
            default() {
                return false;
            }
        },
        uploadFileModalName: {
            type: String,
            default() {
                return null;
            }
        },
    },

    data() {
        return {
            searchKeywords: '',
        }
    },

    methods: {
        inputSearch() {
            this.$emit('inputSearch', this.searchKeywords);
        },

        handleFilters(e) {
            this.$emit('handleFilters', e);
        }
    }
}
</script>

<style scoped>

</style>